<template>
  <g>
    <rect
      fill="currentColor"
      opacity="undefined"
      x="22.66666"
      y="34.33334"
      width="24"
      height="4"
      id="svg_1"
      stroke-width="0"
      rx="3"
      stroke="#444444"
    />
    <rect
      fill="currentColor"
      stroke="#444444"
      opacity="NaN"
      x="-59.43724"
      y="-31.99984"
      width="45.99984"
      height="30.74989"
      id="svg_2"
    />
    <rect
      stroke="#444444"
      fill="currentColor"
      opacity="undefined"
      x="32.8333"
      y="23.33332"
      width="4"
      height="24.1875"
      id="svg_3"
      stroke-width="0"
      rx="3"
    />
  </g>
</template>
